// 提现记录
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">积分商城</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">提现记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="姓名" class="searchboxItem">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="name"
                type="text"
                clearable
                size="small"
                placeholder="请输入姓名"
              />
            </div>
            <div title="手机号" class="searchboxItem">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                clearable
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <div title="到账时间" class="searchboxItem">
              <span class="itemLabel">到账时间:</span>
              <el-date-picker
                v-model="paymentDate"
                size="small"
                 type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="doExport()"
              >导出</el-button
            >
          </div>
          <p style="padding:1rem 0 0 2rem">提现总金额:{{totalWithdraw || 0}}元</p>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="name"
                show-overflow-tooltip
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                width="200"
              />
              <!-- <el-table-column
                label="微信号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              /> -->
              <el-table-column
                label="提现金额"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.amount}}元
                </template>
              </el-table-column>
              <el-table-column
                label="提现状态"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.orderStatus == '1' ?'已完成':'提现中'}}
                </template>
              </el-table-column>
              <el-table-column
                label="申请提现时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
              />
              <el-table-column
                label="到账时间"
                align="left"
                prop="withdrawTime"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "WithdrawalRecordList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      name: "",
      mobile: "",
      paymentDate:'',
      totalWithdraw:0,
    };
  },
  computed: {},
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        name: this.name || "",
        mobile: this.mobile || "",
       startTime: this.paymentDate ? this.paymentDate[0] : '',
        endTime: this.paymentDate ?this.paymentDate[1] : '',
      };
      this.doFetch(
        {
          url: "/bonuspoint/withdraw/v1/order/query",
          params,
          pageNum,
        },
        true,
        2
      );
      this.getSum()
    },
    getSum() {
       const params = {
        name: this.name || "",
        mobile: this.mobile || "",
         startTime: this.paymentDate ? this.paymentDate[0] : '',
        endTime: this.paymentDate ?this.paymentDate[1] : '',
      };
      this.$post('/bonuspoint/withdraw/v1/order/query/sum',params,3000,true,2).then(ret => {
        console.log(ret);
        if(ret.status == 0) {
          this.totalWithdraw = ret.data.totalWithdraw
        }
      }).catch(err => {
        return;
      })
    },
    doExport() {
      this.$post("/bonuspoint/withdraw/v1/order/export", {
        name: this.name || "",
        mobile: this.mobile || "",
      },3000,false,2).then(res=>{
        if(res.status == "0"){
          window.open(res.data);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
